import { StockFetch } from '../main-model'

export default class StockBalanceModel extends StockFetch {
    getStockBalanceBy = (data) => this.authFetch({
        url: 'stock-balance/getStockBalanceBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    getProductStockBalanceBy = (data) => this.authFetch({
        url: 'stock-balance/getProductStockBalanceBy',
        method: 'POST',
        body: JSON.stringify(data),
    })
}