import { BaseFetch } from '../main-model'

export default class UserModel extends BaseFetch {
    checkLogin = (data) => this.directFetch({
        url: 'user/checkLogin',
        method: 'POST',
        body: JSON.stringify(data),
    })

    getUserBy = (data) => this.authFetch({
        url: 'user/getUserBy',
        method: 'POST',
        body: JSON.stringify(data),
    })
}