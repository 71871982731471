import { SaleFetch } from '../main-model'

export default class ReceiptDiscountListModel extends SaleFetch {
    getReceiptDiscountListBy = (data) => this.authFetch({
        url: 'receipt-discount-list/getReceiptDiscountListBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    increaseDiscountListByCode = (data) => this.authFetch({
        url: 'receipt-discount-list/increaseDiscountListByCode',
        method: 'POST',
        body: JSON.stringify(data),
    })
}