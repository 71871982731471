import { SaleFetch } from '../main-model'

export default class ReceiptModel extends SaleFetch {
    generateReceiptLastCode = (data) => this.authFetch({
        url: 'receipt/generateReceiptLastCode',
        method: 'POST',
        body: JSON.stringify(data),
    })

    getReceiptBy = (data) => this.authFetch({
        url: 'receipt/getReceiptBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    getReceiptByCode = (data) => this.authFetch({
        url: 'receipt/getReceiptByCode',
        method: 'POST',
        body: JSON.stringify(data),
    })

    getRequestCancelReceipt = (data) => this.authFetch({
        url: 'receipt/getRequestCancelReceipt',
        method: 'POST',
        body: JSON.stringify(data),
    })

    approveCancelReceiptByCode = (data) => this.authFetch({
        url: 'receipt/approveCancelReceiptByCode',
        method: 'POST',
        body: JSON.stringify(data),
    })

    notCancelReceiptByCode = (data) => this.authFetch({
        url: 'receipt/notCancelReceiptByCode',
        method: 'POST',
        body: JSON.stringify(data),
    })

    insertReceipt = (data) => this.authFetch({
        url: 'receipt/insertReceipt',
        method: 'POST',
        body: JSON.stringify(data),
    })
}