import { StockFetch } from '../main-model'

export default class StockMoveModel extends StockFetch {
    generateStockMoveLastCode = (data) => this.authFetch({
        url: "stock-move/generateStockMoveLastCode",
        method: "POST",
        body: JSON.stringify(data),
    })

    getStockMoveBy = (data) => this.authFetch({
        url: "stock-move/getStockMoveBy",
        method: "POST",
        body: JSON.stringify(data),
    })

    getStockMoveByCode = (data) => this.authFetch({
        url: "stock-move/getStockMoveByCode",
        method: "POST",
        body: JSON.stringify(data),
    })

    getAdviseStockMoveReceive = (data) => this.authFetch({
        url: "stock-move/getAdviseStockMoveReceive",
        method: "POST",
        body: JSON.stringify(data),
    })

    receiveStockMoveBy = (data) => this.authFetch({
        url: "stock-move/receiveStockMoveBy",
        method: "POST",
        body: JSON.stringify(data),
    })

    updateStockMoveBy = (data) => this.authFetch({
        url: "stock-move/updateStockMoveBy",
        method: "POST",
        body: JSON.stringify(data),
    })

    insertStockMove = (data) => this.authFetch({
        url: "stock-move/insertStockMove",
        method: "POST",
        body: JSON.stringify(data),
    })

    deleteStockMoveByCode = (data) => this.authFetch({
        url: "stock-move/deleteStockMoveByCode",
        method: "POST",
        body: JSON.stringify(data),
    })
}
