import { env } from '../GLOBAL'

class MainModel {
    directEndpointFetch = (endpoint, data) => fetch(endpoint, {
        method: data.method,
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: data.body,
    }).then((response) => response.json().then((e) => e)).catch((error) => ({ require: false, data: [], error, }))

    authEndpointFetch = async (endpoint, data) => {
        const response = await fetch(endpoint, {
            method: data.method,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                ...env.ACCESS_TOKEN,
            },
            body: data.body,
        }).then((response) => response.json().then((e) => e)).catch((error) => ({ require: false, data: [], error, }))

        if (response.unauthorized) {
            console.log('unauthorized', response.error)

            localStorage.clear()
            window.location.reload()
        }

        return response
    }
}

export class AccountFetch extends MainModel {
    directFetch = (data) => this.directEndpointFetch(`${env.service_url}${data.url}`, data)
    authFetch = (data) => this.authEndpointFetch(`${env.service_url}${data.url}`, data)
}

export class BaseFetch extends MainModel {
    directFetch = (data) => this.directEndpointFetch(`${env.service_url}${data.url}`, data)
    authFetch = (data) => this.authEndpointFetch(`${env.service_url}${data.url}`, data)
}

export class FinanceFetch extends MainModel {
    directFetch = (data) => this.directEndpointFetch(`${env.service_url}${data.url}`, data)
    authFetch = (data) => this.authEndpointFetch(`${env.service_url}${data.url}`, data)
}

export class PurchaseFetch extends MainModel {
    directFetch = (data) => this.directEndpointFetch(`${env.service_url}${data.url}`, data)
    authFetch = (data) => this.authEndpointFetch(`${env.service_url}${data.url}`, data)
}

export class SaleFetch extends MainModel {
    directFetch = (data) => this.directEndpointFetch(`${env.service_url}${data.url}`, data)
    authFetch = (data) => this.authEndpointFetch(`${env.service_url}${data.url}`, data)
}

export class StockFetch extends MainModel {
    directFetch = (data) => this.directEndpointFetch(`${env.service_url}${data.url}`, data)
    authFetch = (data) => this.authEndpointFetch(`${env.service_url}${data.url}`, data)
}