import { SaleFetch } from '../main-model'

export default class CustomerTypeModel extends SaleFetch {
    generateCustomerTypeLastCode = (data) => this.authFetch({
        url: 'customer-type/generateCustomerTypeLastCode',
        method: 'POST',
        body: JSON.stringify(data),
    })

    getCustomerTypeBy = (data) => this.authFetch({
        url: 'customer-type/getCustomerTypeBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    getCustomerTypeByCode = (data) => this.authFetch({
        url: 'customer-type/getCustomerTypeByCode',
        method: 'POST',
        body: JSON.stringify(data),
    })
}