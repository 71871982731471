import { SaleFetch } from '../main-model'

export default class InvoiceCustomerListModel extends SaleFetch {
    getInvoiceCustomerListBy = (data) => this.authFetch({
        url: 'invoice-customer-list/getInvoiceCustomerListBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    generateInvoiceCustomerListBy = (data) => this.authFetch({
        url: 'invoice-customer-list/generateInvoiceCustomerListBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    getAverageInvoiceCustomerListBy = (data) => this.authFetch({
        url: 'invoice-customer-list/getAverageInvoiceCustomerListBy',
        method: 'POST',
        body: JSON.stringify(data),
    })
}