export const env = { 
  // service_url: 'http://localhost:6201/',
  // export_url: 'http://localhost:81/giftshop_export/', 

  // develop
  // service_url: 'https://rvscs-develop.com/km-korat/',
  // export_url: 'http://giftshop-export.rvscs.com/', 
  // socket_io_url: 'wss://rvscs-develop.com/',
  // socket_io_path: '/km-korat-socket/socket.io/',

  // production
  service_url: 'https://rvscs-prod.com/giftshop-service/',
  export_url: 'https://giftshop-export.rvscs-service.com/',
  socket_io_url: 'wss://rvscs-prod.com/',
  socket_io_path: '/giftshop-socket-service/socket.io/',

  image_url: 'https://rvscs-develop.com/km-korat-web/',
}