import { SaleFetch } from '../main-model'

export default class ReceiptListModel extends SaleFetch {
    getReceiptListBy = (data) => this.authFetch({
        url: 'receipt-list/getReceiptListBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    getLastReceiptListBy = (data) => this.authFetch({
        url: 'receipt-list/getLastReceiptListBy',
        method: 'POST',
        body: JSON.stringify(data),
    })
}