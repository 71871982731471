import { SaleFetch } from '../main-model'

export default class PromotionDiscountListModel extends SaleFetch {
    getPromotionDiscountListBy = (data) => this.authFetch({
        url: 'promotion-discount-list/getPromotionDiscountListBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    increaseDiscountListByCode = (data) => this.authFetch({
        url: 'promotion-discount-list/increaseDiscountListByCode',
        method: 'POST',
        body: JSON.stringify(data),
    })
}