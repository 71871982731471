import { SaleFetch } from '../main-model'

export default class RedeemGiftReceivederModel extends SaleFetch {
    generateRedeemGiftReceivederLastCode = (data) => this.authFetch({
        url: 'redeem-gift-receiveder/generateRedeemGiftReceivederLastCode',
        method: 'POST',
        body: JSON.stringify(data),
    })

    getRedeemGiftReceivederBy = (data) => this.authFetch({
        url: 'redeem-gift-receiveder/getRedeemGiftReceivederBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    getRedeemGiftReceivederByCode = (data) => this.authFetch({
        url: 'redeem-gift-receiveder/getRedeemGiftReceivederByCode',
        method: 'POST',
        body: JSON.stringify(data),
    })

    updateRedeemGiftReceivederBy = (data) => this.authFetch({
        url: 'redeem-gift-receiveder/updateRedeemGiftReceivederBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    insertRedeemGiftReceiveder = (data) => this.authFetch({
        url: 'redeem-gift-receiveder/insertRedeemGiftReceiveder',
        method: 'POST',
        body: JSON.stringify(data),
    })

    deleteRedeemGiftReceivederByCode = (data) => this.authFetch({
        url: 'redeem-gift-receiveder/deleteRedeemGiftReceivederByCode',
        method: 'POST',
        body: JSON.stringify(data),
    })
}