import { BaseFetch } from '../main-model'

export default class NotificationModel extends BaseFetch {
    getNotificationBy = (data) => this.authFetch({
        url: 'notification/getNotificationBy',
        method: 'POST',
        body: JSON.stringify(data),
    })
    getNotificationMenu = (data) => this.authFetch({
        url: 'notification/getNotificationMenu',
        method: 'POST',
        body: JSON.stringify(data),
    })

    updateNotificationBy = (data) => this.authFetch({
        url: 'notification/updateNotificationBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    updateNotificationAll = (data) => this.authFetch({
        url: 'notification/updateNotificationAll',
        method: 'POST',
        body: JSON.stringify(data),
    })
   
    deleteNotificationByCode = (data) => this.authFetch({
        url: 'notification/deleteNotificationByCode',
        method: 'POST',
        body: JSON.stringify(data),
    })
}